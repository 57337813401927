import { Sidebars } from 'components/chat/ChatView';

export const ROUTES = {
  appHome: `/`,
  cookieStatement: 'https://www.jobq.com/cookies',
  fourOhFour: `/404`,
  gesprek: (id: string, sideBar?: Sidebars[number]) =>
    `/gesprekken?` + (id ? `id=${id}` : '') + (sideBar ? `&sidebar=${sideBar}` : ''),
  gesprekken: `/gesprekken` as const,
  homeCandidate: `/werknemer`,
  homeEmployer: `/werkgever`,
  inloggen: `/inloggen`,
  uitloggen: `/uitloggen`,
  match: (id: string) => `/match/${id}`,
  onboardingGelukt: '/onboarding/gelukt',
  onboardingHoeWerktJobQ: '/onboarding/hoe-werkt-jobq',
  onboardingVragen: '/onboarding/vragen',
  privacy: 'https://www.jobq.com/privacy',
  profiel: `/profiel`,
  profielVoorbeeld: `/profiel-voorbeeld`,
  profielVerwijderen: `/profiel/verwijderen`,
  werkgeverAccount: `/werkgever/instellingen/account`,
  werkgeverMessageTemplates: `/werkgever/instellingen/standaardberichten`,
  werkgeverGesprek: (id: string, sideBar?: Sidebars[number]) =>
    `/werkgever/gesprekken?` + (id ? `id=${id}` : '') + (sideBar ? `&sidebar=${sideBar}` : ''),
  werkgeverGesprekken: `/werkgever/gesprekken`,
  werkgeverHome: `/werkgever`,
  werkgeverInloggen: `/werkgever/inloggen`,
  werkgeverProfielVoorbeeld: `/werkgever/profiel-voorbeeld`,
  werkgeverInstellingen: `/werkgever/instellingen`,
  werkgeverKandidaat: (candidateId: string) => `/werkgever/kandidaat/${candidateId}`,
  werkgeverRegistrerenChat: `/werkgever/registreren`,
  werkgeverRegistrerenFormulier: `/werkgever/registreren-formulier`,
  werkgeverWachtwoordVergeten: `/werkgever/wachtwoord-vergeten`,
  werkgeverZoekopdracht: (id: string) => `/werkgever/zoekopdrachten/${id}`,
  werkgeverZoekopdrachtAanmaken: `/werkgever/zoekopdrachten/aanmaken`,
  werkgeverZoekopdrachtBewerken: (id: string) => `/werkgever/zoekopdrachten/${id}/bewerken`,
  werkgeverZoekopdrachten: `/werkgever/zoekopdrachten`,
  werkgeverZoekopdrachtKandidaat: (id: string, candidateId: string) =>
    `/werkgever/zoekopdrachten/${id}/kandidaat/${candidateId}`,
  werknemerHome: '/werknemer',
  werkgeverMessageTemplateBlog:
    'https://www.jobq.com/werkgever/blog/hoe-verstuur-je-een-effectief-connectieverzoek-op-jobq',
};
