import { Button } from 'components/Button';
import { FlexRow } from 'components/FlexRow';
import { Popup } from 'components/Popup';
import { RichText } from 'components/RichText';
import { VerticalMargin } from 'components/VerticalMargin';
import React from 'react';
import styles from './AlertPopup.module.css';

export interface AlertPopupProperties {
  onClose?: () => void;
  onProceed?: () => void;
  title: string;
  description?: string;
  proceedButtonLabel?: string;
  cancelButtonLabel?: string;
  invertedButtonStyle?: boolean;
  error?: string;
  children?: React.ReactNode;
}

export const AlertPopup = ({
  onClose,
  onProceed,
  title,
  description,
  proceedButtonLabel = 'Bevestigen',
  cancelButtonLabel = 'Annuleren',
  invertedButtonStyle = false,
  children,
  error,
}: AlertPopupProperties) => (
  <Popup onClose={onClose}>
    <RichText>
      <VerticalMargin>
        <h2>{title}</h2>
        <p>{description}</p>
      </VerticalMargin>
      {children}
      <FlexRow>
        {!!onProceed && !!proceedButtonLabel && (
          <Button theme={invertedButtonStyle ? undefined : 'secondary'} onClick={onProceed}>
            {proceedButtonLabel}
          </Button>
        )}
        {!!onClose && !!cancelButtonLabel && (
          <Button theme={invertedButtonStyle ? 'secondary' : undefined} onClick={onClose}>
            {cancelButtonLabel}
          </Button>
        )}
      </FlexRow>
      <VerticalMargin size="small" />
      {!!error && <p className={styles.error}>{error}</p>}
    </RichText>
  </Popup>
);
