import { QueryObserverResult, useQueryClient } from '@tanstack/react-query';
import { AlertPopup } from 'components/AlertPopup';
import { Candidate_candidate_view_self, Employer_employer_view_self, JWTAuth } from 'generated/api';
import { useSwitchEmployerAccount } from 'hooks/useSwitchEmployerAccount';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { activateCandidate, candidateLoginCheck, employerLogin, useCandidateMe, useEmployerMe } from 'utils/api';
import { clearLoggedInCookie, setLoggedInCookie } from 'utils/cookies';
import { TokenType, getTokenTypeForAccessToken, getTokens, removeTokens, saveTokens } from 'utils/tokens';

interface UserContextType<T = Employer_employer_view_self | Candidate_candidate_view_self> {
  isLoadingUser: boolean;
  loginCandidate: (data: { expires: string; hash: string; user: string }) => Promise<void>;
  loginEmployer: (data: JWTAuth) => Promise<void>;
  logout: () => void;
  onActivateCandidate: (code: string) => Promise<void>;
  onActivateEmployer: (data: { token?: string; refresh_token?: string }) => Promise<void>;
  refetchUser: () => Promise<
    | QueryObserverResult<Employer_employer_view_self, unknown>
    | QueryObserverResult<Candidate_candidate_view_self, unknown>
    | void
  >;
  tokenType?: TokenType;
  user?: T;
  updateUser: () => Promise<void>;
}

const initialState = {
  isLoadingUser: false,
  loginEmployer: async () => {
    // init
  },
  onActivateEmployer: async () => {
    // init
  },
  loginCandidate: async () => {
    // init
  },
  onActivateCandidate: async () => {
    // init
  },

  logout: () => {
    // init
  },
  refetchUser: async () => {
    // init
  },
  tokenType: undefined,
  user: undefined,
  updateUser: async () => {
    // init
  },
};

const UserContext = createContext<UserContextType>(initialState);

interface Properties {
  children: ReactNode;
  isRefreshingUser: boolean;
}

function getInitialTokenType() {
  const tokens = getTokens();

  if (!tokens?.accessToken) {
    return;
  }

  return getTokenTypeForAccessToken(tokens.accessToken);
}

export const UserProvider = ({ children, isRefreshingUser }: Properties) => {
  const [tokenType, setTokenType] = useState<TokenType | undefined>();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const queryClient = useQueryClient();

  const {
    data: employerData,
    isLoading: isLoadingEmployer,
    isFetching: isFetchingEmployer,
    refetch: refetchEmployer,
  } = useEmployerMe({ enabled: tokenType === TokenType.Employer });

  const {
    data: candidateData,
    isLoading: isLoadingCandidate,
    isFetching: isFetchingCandidate,
    refetch: refetchCandidate,
  } = useCandidateMe({ enabled: tokenType === TokenType.Candidate });

  const user = useMemo(() => {
    if (tokenType === TokenType.Employer) {
      return employerData;
    } else if (tokenType === TokenType.Candidate) {
      return candidateData;
    }
  }, [candidateData, employerData, tokenType]);

  const { showSwitchEmployerAlert } = useSwitchEmployerAccount();

  const refetchUser = useCallback(async () => {
    setIsLoadingUser(true);
    const userPromise = tokenType === TokenType.Employer ? refetchEmployer : refetchCandidate;
    return await userPromise();
  }, [refetchCandidate, refetchEmployer, tokenType]);

  const loginEmployer = useCallback(
    async (data: JWTAuth) => {
      const response = await employerLogin(data);

      if (!response.token || !response.refresh_token) {
        throw new Error('Missing token or refresh token');
      }

      saveTokens(response.token, response.refresh_token);
      const newTokenType = getTokenTypeForAccessToken(response.token);

      if (newTokenType !== tokenType) {
        setTokenType(newTokenType);
      }

      setLoggedInCookie('as_employer');
      refetchEmployer();
    },
    [refetchEmployer, tokenType]
  );

  const onActivateEmployer = useCallback(
    async (data: { token?: string; refresh_token?: string }) => {
      if (!data.token || !data.refresh_token) {
        throw new Error('Missing token or refresh token');
      }

      saveTokens(data.token, data.refresh_token);
      const newTokenType = getTokenTypeForAccessToken(data.token);

      if (newTokenType !== tokenType) {
        setTokenType(newTokenType);
      }

      setLoggedInCookie('as_employer');
      refetchEmployer();
    },
    [refetchEmployer, tokenType]
  );

  const loginCandidate = useCallback(
    async ({ expires, hash, user }: { expires: string; hash: string; user: string }) => {
      const response = await candidateLoginCheck({
        expires: expires.toString(),
        hash: hash.toString(),
        user: user.toString(),
      });

      if (!response.token || !response.refresh_token) {
        throw new Error('Missing token or refresh token');
      }

      saveTokens(response.token, response.refresh_token);
      const newTokenType = getTokenTypeForAccessToken(response.token);

      if (newTokenType !== tokenType) {
        setTokenType(newTokenType);
      }
      setLoggedInCookie('as_candidate');
      refetchCandidate();
    },
    [refetchCandidate, tokenType]
  );

  const onActivateCandidate = useCallback(
    async (code: string) => {
      const { token, refresh_token } = await activateCandidate(code);

      if (!token || !refresh_token) {
        throw new Error('Missing token or refresh token');
      }

      saveTokens(token, refresh_token);
      const newTokenType = getTokenTypeForAccessToken(token);

      if (newTokenType !== tokenType) {
        setTokenType(newTokenType);
      }
      setLoggedInCookie('as_candidate');
      refetchCandidate();
    },
    [refetchCandidate, tokenType]
  );

  const updateUser = useCallback(async () => {
    if (tokenType === TokenType.Employer) {
      await refetchEmployer();
    } else if (tokenType === TokenType.Candidate) {
      await refetchCandidate();
    }
  }, [refetchCandidate, refetchEmployer, tokenType]);

  const logout = useCallback(() => {
    clearLoggedInCookie();
    removeTokens();
    setTokenType(undefined);
    queryClient.resetQueries();
  }, [queryClient]);

  useEffect(() => {
    if (tokenType === TokenType.Employer) {
      setIsLoadingUser(isFetchingEmployer || isLoadingEmployer);
    } else if (tokenType === TokenType.Candidate) {
      setIsLoadingUser(isFetchingCandidate || isLoadingCandidate);
    }
  }, [isFetchingCandidate, isFetchingEmployer, isLoadingCandidate, isLoadingEmployer, tokenType]);

  useEffect(() => {
    const initialTokenType = getInitialTokenType();
    if (!initialTokenType) {
      return setIsLoadingUser(false);
    }
    setTokenType(initialTokenType);
  }, []);

  const value = useMemo(
    () => ({
      isLoadingUser: isLoadingUser || isRefreshingUser,
      setIsLoadingUser,
      loginCandidate,
      loginEmployer,
      logout,
      onActivateCandidate,
      onActivateEmployer,
      refetchUser,
      tokenType,
      user,
      updateUser,
    }),
    [
      isLoadingUser,
      isRefreshingUser,
      setIsLoadingUser,
      loginCandidate,
      loginEmployer,
      logout,
      onActivateCandidate,
      onActivateEmployer,
      refetchUser,
      tokenType,
      updateUser,
      user,
    ]
  );

  return (
    <UserContext.Provider value={value}>
      {children}

      {!!showSwitchEmployerAlert && (
        <AlertPopup
          title="Je bent actief in een ander tabblad"
          description="Sluit dit tabblad om door te gaan met de huidige ingelogde werkgever."
        />
      )}
    </UserContext.Provider>
  );
};

export const useUser = <T extends Employer_employer_view_self | Candidate_candidate_view_self>(): UserContextType<T> =>
  // TODO: Fix this type later
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useContext(UserContext);
