import { useEffect, useState } from 'react';
import { broadcastChannel } from 'utils/updateLocalStorageChannel';

export const useSwitchEmployerAccount = () => {
  const [showSwitchEmployerAlert, setShowSwitchEmployerAlert] = useState(false);

  useEffect(() => {
    const broadcastListener = async () => {
      setShowSwitchEmployerAlert(true);
    };

    broadcastChannel?.addEventListener('message', broadcastListener);

    return () => {
      broadcastChannel?.removeEventListener('message', broadcastListener);
    };
  }, []);

  return { showSwitchEmployerAlert };
};
