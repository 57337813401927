/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Connection_jsonld_message_employer } from './Connection_jsonld_message_employer';
export type Message_jsonld_message_employer = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly uuid: string;
    readonly sentAt: string;
    /**
     * this field is required in the database
     */
    direction: Message_jsonld_message_employer.direction;
    connection: Connection_jsonld_message_employer;
    contents: string;
    type: Message_jsonld_message_employer.type;
    readonly readByReceiver: boolean | null;
};
export namespace Message_jsonld_message_employer {
    /**
     * this field is required in the database
     */
    export enum direction {
        EMPLOYER_TO_CANDIDATE = 'EmployerToCandidate',
        CANDIDATE_TO_EMPLOYER = 'CandidateToEmployer',
    }
    export enum type {
        CHAT = 'Chat',
        SYSTEM = 'System',
    }
}

