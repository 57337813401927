import Cookies from 'js-cookie';

const COOKIE_LOGGED_IN_KEY = 'COOKIE_LOGGED_IN';
export const COOKIE_SHOW_CHAT_CTA_KEY = 'COOKIE_SHOW_CHAT_CTA';

export const setLoggedInCookie = (value: 'as_employer' | 'as_candidate') => {
  Cookies.set(COOKIE_LOGGED_IN_KEY, value, {
    expires: 365,
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || undefined,
  });
};

export const clearLoggedInCookie = () => {
  Cookies.remove(COOKIE_LOGGED_IN_KEY, {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || undefined,
  });
};

export const setCookie = (key: string, value: string, expires?: number) => {
  Cookies.set(key, value, {
    expires: expires ?? 2,
  });
};

export const getCookie = (key: string) => Cookies.get(key);
