/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatRequest } from '../models/ChatRequest';
import type { ChatRequest_Connection_connection_employer } from '../models/ChatRequest_Connection_connection_employer';
import type { Connection_connection_candidate } from '../models/Connection_connection_candidate';
import type { Connection_connection_employer } from '../models/Connection_connection_employer';
import type { Message_message_candidate } from '../models/Message_message_candidate';
import type { Message_message_candidate_create } from '../models/Message_message_candidate_create';
import type { Message_message_employer } from '../models/Message_message_employer';
import type { Message_message_employer_create } from '../models/Message_message_employer_create';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChatService {
    /**
     * Retrieves the collection of Connection resources.
     * Retrieves the collection of Connection resources.
     * @returns Connection_connection_candidate Connection collection
     * @throws ApiError
     */
    public static candidateListConnections({
        page = 1,
        itemsPerPage = 30,
        status,
        statusArray,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        status?: string,
        statusArray?: Array<string>,
    }): CancelablePromise<Array<Connection_connection_candidate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/chat_connections',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'status': status,
                'status[]': statusArray,
            },
        });
    }
    /**
     * Unread messages
     * Counts unread messages for all connections for the current user
     * @returns any Amount of unread messages per connection (UUID) and a total
     * @throws ApiError
     */
    public static candidateCountUnreadMessages(): CancelablePromise<{
        '{uuid}'?: number;
        total?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/chat_connections/unread_message_count',
        });
    }
    /**
     * Retrieves a Connection resource.
     * Retrieves a Connection resource.
     * @returns Connection_connection_candidate Connection resource
     * @throws ApiError
     */
    public static candidateViewConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<Connection_connection_candidate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/chat_connections/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Accept a chat request
     * Accept a chat request from an employer
     * @returns void
     * @throws ApiError
     */
    public static candidateAcceptConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/accept',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot accept chat (see code for reason)`,
            },
        });
    }
    /**
     * Accept the data sharing request from the employer
     * Accept to share additional personal information with the employer
     * @returns void
     * @throws ApiError
     */
    public static candidateAcceptDataSharing({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/accept_data_share_request',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot accept data sharing request(see code for reason)`,
            },
        });
    }
    /**
     * Cancel a chat request
     * Cancel a chat request
     * @returns void
     * @throws ApiError
     */
    public static candidateCancelConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/cancel',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot cancel chat (see code for reason)`,
            },
        });
    }
    /**
     * Decline a chat request
     * Decline a chat request from an employer
     * @returns Connection_connection_candidate Connection resource updated
     * @throws ApiError
     */
    public static candidateDeclineConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<Connection_connection_candidate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/decline',
            path: {
                'uuid': uuid,
            },
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Decline the data sharing request from the employer
     * Decline to refuse sharing additional personal information with the employer
     * @returns void
     * @throws ApiError
     */
    public static candidateDeclineDataSharing({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/decline_data_share_request',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot decline data sharing request(see code for reason)`,
            },
        });
    }
    /**
     * Retrieves the collection of Message resources.
     * Retrieves the collection of Message resources.
     * @returns Message_message_candidate Message collection
     * @throws ApiError
     */
    public static candidateListMessages({
        page = 1,
        itemsPerPage = 30,
        connectionUuid,
        connectionUuidArray,
        sentAtBefore,
        sentAtStrictlyBefore,
        sentAtAfter,
        sentAtStrictlyAfter,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        connectionUuid?: string,
        connectionUuidArray?: Array<string>,
        sentAtBefore?: string,
        sentAtStrictlyBefore?: string,
        sentAtAfter?: string,
        sentAtStrictlyAfter?: string,
    }): CancelablePromise<Array<Message_message_candidate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/chat_messages',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'connection.uuid': connectionUuid,
                'connection.uuid[]': connectionUuidArray,
                'sentAt[before]': sentAtBefore,
                'sentAt[strictly_before]': sentAtStrictlyBefore,
                'sentAt[after]': sentAtAfter,
                'sentAt[strictly_after]': sentAtStrictlyAfter,
            },
        });
    }
    /**
     * Creates a Message resource.
     * Creates a Message resource.
     * @returns Message_message_candidate Message resource created
     * @throws ApiError
     */
    public static candidateCreateMessage({
        requestBody,
    }: {
        /**
         * The new Message resource
         */
        requestBody: Message_message_candidate_create,
    }): CancelablePromise<Message_message_candidate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/chat_messages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Message resource.
     * Retrieves a Message resource.
     * @returns Message_message_candidate Message resource
     * @throws ApiError
     */
    public static candidateViewMessage({
        uuid,
    }: {
        /**
         * Message identifier
         */
        uuid: string,
    }): CancelablePromise<Message_message_candidate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/chat_messages/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Request a new chat
     * Request a new chat with the given candidate
     * @returns ChatRequest_Connection_connection_employer ChatRequest resource created
     * @throws ApiError
     */
    public static employerRequestChat({
        uuid,
        requestBody,
    }: {
        uuid: string,
        /**
         * The new ChatRequest resource
         */
        requestBody: ChatRequest,
    }): CancelablePromise<ChatRequest_Connection_connection_employer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/candidates/{uuid}/request_chat',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves the collection of Connection resources.
     * Retrieves the collection of Connection resources.
     * @returns Connection_connection_employer Connection collection
     * @throws ApiError
     */
    public static employerListConnections({
        page = 1,
        itemsPerPage = 30,
        status,
        statusArray,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        status?: string,
        statusArray?: Array<string>,
    }): CancelablePromise<Array<Connection_connection_employer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/chat_connections',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'status': status,
                'status[]': statusArray,
            },
        });
    }
    /**
     * Unread messages
     * Counts unread messages for all connections for the current user
     * @returns any Amount of unread messages per connection (UUID) and a total
     * @throws ApiError
     */
    public static employerCountUnreadMessages(): CancelablePromise<{
        '{uuid}'?: number;
        total?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/chat_connections/unread_message_count',
        });
    }
    /**
     * Retrieves a Connection resource.
     * Retrieves a Connection resource.
     * @returns Connection_connection_employer Connection resource
     * @throws ApiError
     */
    public static employerViewConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<Connection_connection_employer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/chat_connections/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Cancel a chat request
     * Cancel a chat request
     * @returns void
     * @throws ApiError
     */
    public static employerCancelConnection({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/chat_connections/{uuid}/cancel',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot cancel chat (see code for reason)`,
            },
        });
    }
    /**
     * Request data sharing from a candidate
     * Requests additional data from the candidate
     * @returns void
     * @throws ApiError
     */
    public static employerRequestDataSharing({
        uuid,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/chat_connections/{uuid}/request_data_sharing',
            path: {
                'uuid': uuid,
            },
            errors: {
                422: `Cannot request data sharing (see code for reason)`,
            },
        });
    }
    /**
     * Retrieves the collection of Message resources.
     * Retrieves the collection of Message resources.
     * @returns Message_message_employer Message collection
     * @throws ApiError
     */
    public static employerListMessages({
        page = 1,
        itemsPerPage = 30,
        connectionUuid,
        connectionUuidArray,
        sentAtBefore,
        sentAtStrictlyBefore,
        sentAtAfter,
        sentAtStrictlyAfter,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        connectionUuid?: string,
        connectionUuidArray?: Array<string>,
        sentAtBefore?: string,
        sentAtStrictlyBefore?: string,
        sentAtAfter?: string,
        sentAtStrictlyAfter?: string,
    }): CancelablePromise<Array<Message_message_employer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/chat_messages',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'connection.uuid': connectionUuid,
                'connection.uuid[]': connectionUuidArray,
                'sentAt[before]': sentAtBefore,
                'sentAt[strictly_before]': sentAtStrictlyBefore,
                'sentAt[after]': sentAtAfter,
                'sentAt[strictly_after]': sentAtStrictlyAfter,
            },
        });
    }
    /**
     * Creates a Message resource.
     * Creates a Message resource.
     * @returns Message_message_employer Message resource created
     * @throws ApiError
     */
    public static employerCreateMessage({
        requestBody,
    }: {
        /**
         * The new Message resource
         */
        requestBody: Message_message_employer_create,
    }): CancelablePromise<Message_message_employer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/chat_messages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Message resource.
     * Retrieves a Message resource.
     * @returns Message_message_employer Message resource
     * @throws ApiError
     */
    public static employerViewMessage({
        uuid,
    }: {
        /**
         * Message identifier
         */
        uuid: string,
    }): CancelablePromise<Message_message_employer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/chat_messages/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
