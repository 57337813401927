import { GeoCodingLatLong } from 'utils/interfaces';

export function getLocationLatlong(locationData?: GeoCodingLatLong) {
  if (!locationData) {
    return;
  }

  if (locationData?.response?.numFound === 0) {
    return;
  }

  // eslint-disable-next-line unicorn/prefer-string-replace-all
  const longLat = locationData?.response?.docs?.[0]?.centroide_ll.replace(/^POINT\(|\)$/g, '').split(' ');

  if (!longLat?.[1] || !longLat?.[0]) {
    return;
  }

  return {
    lat: Number.parseFloat(longLat?.[1]),
    lng: Number.parseFloat(longLat?.[0]),
  };
}
