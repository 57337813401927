export const hideCookieBannerDescription = (pathName: string) => {
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      //@ts-expect-error - we know the className exist
      if (mutation.target.className.includes('cky')) {
        const cookieBarContainer = document.querySelector('.cky-consent-container');

        if (!!cookieBarContainer && pathName === '/inloggen') {
          //@ts-expect-error - we know the style property exist
          cookieBarContainer.style.display = 'none';
          observer.disconnect();
          return;
        }

        const cookieBarDescriptionContainer = document.querySelector('.cky-notice-des');
        if (cookieBarDescriptionContainer) {
          const readMoreClass = 'read-more';
          //if element with class already exists, return and disconnect observer
          if (document.querySelector(`.${readMoreClass}`)) {
            observer.disconnect();
            return;
          }
          // ....
          //@ts-expect-error - we know the style tag exist
          cookieBarDescriptionContainer.style.display = 'none';
          const readMoreButton = document.createElement('a');
          readMoreButton.className = `${readMoreClass} cky-policy cky-notice-des`;
          readMoreButton.style.textDecoration = 'underline';
          readMoreButton.style.cursor = 'pointer';
          readMoreButton.textContent = 'Lees meer';
          readMoreButton.addEventListener('click', () => {
            //@ts-expect-error - we know the style tag exist
            const isHidden = cookieBarDescriptionContainer.style.display === 'none';
            //@ts-expect-error - we know the style tag exist
            cookieBarDescriptionContainer.style.display = isHidden ? 'block' : 'none';
            readMoreButton.textContent = isHidden ? 'Lees minder' : 'Lees meer';
          });

          cookieBarDescriptionContainer.parentNode?.insertBefore(
            readMoreButton,
            cookieBarDescriptionContainer.nextSibling
          );
          observer.disconnect();
        }
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};
