import './global.css';

import { PageHeader } from 'components/PageHeader';
import { PublicAndTypeStaticProperties } from 'utils/interfaces';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { trackPageView } from 'utils/tracking';
import { useRouter } from 'next/router';
import { UserProvider } from 'context/UserProvider';
import classnames from 'classnames';
import Head from 'next/head';
import styles from './_app.module.css';
import type { AppProps } from 'next/app';
import { UnreadMessagesProvider } from 'context/UnreadMessagesProvider';
import Script from 'next/script';
import { BackgroundQShapeFade } from 'components/BackgroundQShape';
import { ProtectApplicationFromUnwantedUsers } from '../components/ProtectApplicationFromUnwantedUsers';
import { useQueryClient } from 'hooks/useQueryClient';
import { hideCookieBannerDescription } from '../utils/hideCookieBannerDescription';
import { captureException } from 'utils/captureException';

const NO_PAGE_HEADER_CLASS = 'noPageHeader';

const PAGE_META_DEFAULTS = {
  description: 'Waarom zou jij nog op zoek gaan? Met JobQ laat je de werkgever zoeken naar jou!',
  title: 'JobQ',
};

const MyApp = ({ Component, pageProps }: AppProps<PublicAndTypeStaticProperties>) => {
  const router = useRouter();
  const [isRefreshingUser, setIsRefreshingUser] = useState(false);

  useEffect(() => {
    const onRouteChange = (url: URL) => {
      trackPageView(url);
    };

    router.events.on('routeChangeComplete', onRouteChange);
    return () => router.events.off('routeChangeComplete', onRouteChange);
  }, [router, router.asPath, router.events]);

  const queryClient = useQueryClient(router, setIsRefreshingUser);

  useEffect(() => {
    try {
      hideCookieBannerDescription(router.asPath);
    } catch (error) {
      captureException(error, { tags: { fn: 'hideCookieBannerDescription' } });
    }
  }, [router.asPath]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider isRefreshingUser={isRefreshingUser}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ProtectApplicationFromUnwantedUsers
          isPublicPage={pageProps.isPublicPage}
          shouldBeEmployer={pageProps.shouldBeEmployer}>
          <UnreadMessagesProvider>
            <Page
              hideBackgroundShape={pageProps.hideBackgroundShape}
              hideHeader={pageProps.pageHideHeader}
              transparentHeader={pageProps.pageTransparentHeader}
              positionAbsoluteHeader={pageProps.positionAbsoluteHeader}
              isPublicPage={pageProps.isPublicPage}
              overrideBackgroundShapeColor={pageProps.overrideBackgroundShapeColor}>
              <Component {...pageProps} />
            </Page>
          </UnreadMessagesProvider>
        </ProtectApplicationFromUnwantedUsers>
      </UserProvider>
    </QueryClientProvider>
  );
};

export interface ProtectApplicationFromUnwantedUsersProperties extends PublicAndTypeStaticProperties {
  children: ReactNode;
}

interface PageProperties {
  children?: ReactNode;
  hideHeader?: boolean;
  isPublicPage?: boolean;
  transparentHeader?: boolean;
  positionAbsoluteHeader?: boolean;
  hideBackgroundShape?: boolean;
  overrideBackgroundShapeColor?: 'green' | 'blue';
}

const NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID;
const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

const Page = ({
  children,
  hideBackgroundShape,
  hideHeader = false,
  positionAbsoluteHeader = false,
  transparentHeader = false,
  isPublicPage,
  overrideBackgroundShapeColor,
}: PageProperties) => {
  useEffect(() => {
    if (hideHeader) {
      document.body.classList.add(NO_PAGE_HEADER_CLASS);
    } else {
      document.body.classList.remove(NO_PAGE_HEADER_CLASS);
    }
  }, [hideHeader]);

  return (
    <div className={classnames(styles.container, { [styles.noPageHeader]: hideHeader })}>
      <Head>
        <title>{PAGE_META_DEFAULTS.title}</title>
        <meta name="title" content={PAGE_META_DEFAULTS.title} />
        <meta property="og:title" content={PAGE_META_DEFAULTS.title} />
        <meta name="description" content={PAGE_META_DEFAULTS.description} />
        <meta property="og:description" content={PAGE_META_DEFAULTS.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@LinkUimedia" />
        <meta property="twitter:title" content={PAGE_META_DEFAULTS.title} />
        <meta property="twitter:description" content={PAGE_META_DEFAULTS.description} />
        <meta name="facebook-domain-verification" content="x55zyfbymkeplatkauvghy21qhmk7j" />
      </Head>
      {NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ? (
        <Fragment>
          <Script
            id="gtm-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
            }}
          />
          <noscript>
            <iframe
              sandbox=""
              title="Google tag manager"
              src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </Fragment>
      ) : undefined}
      {NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID ? (
        <Fragment>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`}
          />
          <Script
            id="ga-datalayer"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}');
              `,
            }}
          />
        </Fragment>
      ) : undefined}

      {!hideHeader && (
        <PageHeader transparent={transparentHeader} positionAbsolute={positionAbsoluteHeader} isPublic={isPublicPage} />
      )}
      <main className={classnames(styles.main, { [styles.noPageHeader]: hideHeader })}>{children}</main>
      {hideBackgroundShape ? undefined : (
        <div className={styles.backgroundContainer}>
          <BackgroundQShapeFade overrideColor={overrideBackgroundShapeColor} />
        </div>
      )}
    </div>
  );
};

export default MyApp;
