/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feedback } from '../models/Feedback';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedbackService {
    /**
     * Creates a Feedback resource.
     * Creates a Feedback resource.
     * @returns Feedback Feedback resource created
     * @throws ApiError
     */
    public static candidateGiveFeedback({
        requestBody,
    }: {
        /**
         * The new Feedback resource
         */
        requestBody: Feedback,
    }): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Creates a Feedback resource.
     * Creates a Feedback resource.
     * @returns Feedback Feedback resource created
     * @throws ApiError
     */
    public static employerGiveFeedback({
        requestBody,
    }: {
        /**
         * The new Feedback resource
         */
        requestBody: Feedback,
    }): CancelablePromise<Feedback> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
}
