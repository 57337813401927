import { jwtDecode } from 'jwt-decode';
import { captureException } from 'utils/captureException';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'JOBQ_AT';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'JOBQ_RT';

const ADMIN_ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'JOBQ_AT_ADMIN';
const ADMIN_REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'JOBQ_RT_ADMIN';

export const getTokens = () => {
  if (typeof window === 'undefined') return;

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  return { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };
};

export const getAdminTokens = () => {
  if (typeof window === 'undefined') return;

  const accessToken = window.localStorage.getItem(ADMIN_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const refreshToken = window.localStorage.getItem(ADMIN_REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  return { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);

  return { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };
};

export const saveAdminTokens = (accessToken: string) => {
  if (typeof window === 'undefined') return;
  //For now we do not save the refresh token for admin

  window.localStorage.setItem(ADMIN_ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);

  return { accessToken: accessToken || undefined };
};

export const removeTokens = () => {
  if (typeof window === 'undefined') return;

  window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  return { accessToken: undefined, refreshToken: undefined };
};

export enum TokenType {
  Employer = 'Employer',
  Candidate = 'Candidate',
  Admin = 'Admin',
}

export function getTokenTypeForAccessToken(accessToken: string) {
  const decodedToken = jwtDecode<{ type: TokenType }>(accessToken);
  switch (decodedToken.type) {
    case TokenType.Admin: {
      return TokenType.Admin;
    }
    case TokenType.Employer: {
      return TokenType.Employer;
    }
    case TokenType.Candidate: {
      return TokenType.Candidate;
    }
    default: {
      if (decodedToken.type) {
        captureException(`Found token type with unknown type: ${decodedToken.type}`);
      } else {
        captureException(`Found token without type`);
      }
    }
  }
}

export function getTokenDetails(accessToken: string) {
  const decodedToken = jwtDecode<{ type: TokenType }>(accessToken);
  return decodedToken;
}
