import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const BackgroundQShapeFade = ({ overrideColor }: { overrideColor?: 'green' | 'blue' }) => {
  const router = useRouter();

  const colorAndOpacity = useMemo(() => {
    let color = '';
    let opacity = '';
    if (overrideColor) {
      switch (overrideColor) {
        case 'green': {
          color = '#B8FC2D';
          opacity = '0.3';
          break;
        }
        case 'blue': {
          color = '#4EE5FF';
          opacity = '0.2';
          break;
        }
        default: {
          color = '#B8FC2D';
          opacity = '0.3';
        }
      }
      return { color, opacity };
    }
    return {
      color: overrideColor === 'blue' || router.asPath.startsWith('/werkgever') ? '#4EE5FF' : '#B8FC2D',
      opacity: router.asPath.startsWith('/werkgever') ? '0.2' : '0.3',
    };
  }, [overrideColor, router.asPath]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 860 770">
      <g filter="url(#filter0_f_23_4924)">
        <path
          fill="url(#paint0_linear_23_4924)"
          fillOpacity={colorAndOpacity.opacity}
          d="m828.8 627.2-28-156.7a76 76 0 1 0-149.4 26.7l10.7 60-73.3-5.6a79.4 79.4 0 0 0-40.2 8 249.4 249.4 0 0 1-290-46.1 250.7 250.7 0 0 1 0-354.1A75.8 75.8 0 0 0 151.5 52.1a399.6 399.6 0 0 0-93.9 148.2A476.4 476.4 0 0 0 30 354.4c0 51.7 7.9 96.5 26 142.8 18.3 46.3 36.6 76.7 79.9 120 77.3 77.5 195.4 121.9 299.5 121.9 54.6 0 109.8-11.4 162-34.4l151 11.6a75.3 75.3 0 0 0 62-24.9 75.5 75.5 0 0 0 18.4-64.2Z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_23_4924"
          x1="230.871"
          x2="-22.5005"
          y1="740.013"
          y2="136.345"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={colorAndOpacity.color} />
          <stop offset="1" stopColor="#D3F8FF" stopOpacity="0.1" />
        </linearGradient>
        <filter
          id="filter0_f_23_4924"
          width="860"
          height="769.092"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_23_4924" stdDeviation="15" />
        </filter>
      </defs>
    </svg>
  );
};
