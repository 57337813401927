/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Certificate_certificate_view } from '../models/Certificate_certificate_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CertificateService {
    /**
     * Retrieves the collection of Certificate resources.
     * Retrieves the collection of Certificate resources.
     * @returns Certificate_certificate_view Certificate collection
     * @throws ApiError
     */
    public static listCertifications({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Certificate_certificate_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/certificates',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }
    /**
     * Retrieves a Certificate resource.
     * Retrieves a Certificate resource.
     * @returns Certificate_certificate_view Certificate resource
     * @throws ApiError
     */
    public static viewCertification({
        id,
    }: {
        /**
         * Certificate identifier
         */
        id: string,
    }): CancelablePromise<Certificate_certificate_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/certificates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
