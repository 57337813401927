/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Employer_employer_admin_list = {
    readonly uuid: string;
    prefix: string;
    accountStatus: Employer_employer_admin_list.accountStatus;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    city: string;
};
export namespace Employer_employer_admin_list {
    export enum accountStatus {
        DEMO = 'demo',
        FULL = 'full',
        SUMMER_DEAL = 'summer-deal',
    }
}

