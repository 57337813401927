import { QueryClient, QueryCache, MutationCache, DefaultError } from '@tanstack/react-query';
import { Dispatch, useRef } from 'react';
import { NextRouter } from 'next/router';
import { clearLoggedInCookie } from 'utils/cookies';
import { refreshUser } from 'utils/refreshUser';
import { ApiError } from 'generated/api';
import { removeTokens } from 'utils/tokens';
import { captureException } from 'utils/captureException';

const QUERY_OPTIONS = {
  retry: (failureCount: number, error: Error) => failureCount < 1 && error instanceof ApiError && error.status === 401,
};

const getMutationOptions = (queryClient?: QueryClient) => {
  if (!queryClient) {
    return;
  }
  return {
    retry: (failureCount: number) => {
      if (failureCount > 1) {
        return false;
      }
      refreshUser(queryClient, false);
      return true;
    },
  };
};

export const useQueryClient = (
  router: NextRouter,
  setIsRefreshingUser: React.Dispatch<React.SetStateAction<boolean>>
) => {
  //This useRef is needed because otherwise we cannot reference the queryClient in the onQueryError function
  const queryClientReference = useRef<QueryClient>();

  if (!queryClientReference.current) {
    queryClientReference.current = new QueryClient({
      queryCache: new QueryCache({
        onError: (error) =>
          onQueryError({ setIsRefreshingUser, router, error, queryClient: queryClientReference.current }),
      }),
      mutationCache: new MutationCache({
        onError: (error) =>
          onQueryError({
            setIsRefreshingUser,
            router,
            error,
            queryClient: queryClientReference.current,
          }),
      }),
    });
  }

  //Here we need a partial copy, because we need to set the defaultOptions with the initial queryClient
  const queryClientWithOptions = new QueryClient({
    defaultOptions: {
      mutations: getMutationOptions(queryClientReference.current),

      queries: QUERY_OPTIONS,
    },
    queryCache: queryClientReference.current.getQueryCache(),
    mutationCache: queryClientReference.current.getMutationCache(),
  });

  return queryClientWithOptions;
};

async function onQueryError({
  error,
  router,
  setIsRefreshingUser,
  queryClient,
}: {
  error: DefaultError;
  router: NextRouter;
  setIsRefreshingUser: Dispatch<boolean>;
  queryClient: QueryClient | undefined;
}) {
  if (!queryClient) return;
  const isUnauthorizedError = error instanceof ApiError && error.status === 401;

  const isAdminRoute = router.asPath.includes('/admin');

  if (isUnauthorizedError) {
    try {
      setIsRefreshingUser(true);
      await refreshUser(queryClient, isAdminRoute);
      setIsRefreshingUser(false);
    } catch {
      removeTokens();
      clearLoggedInCookie();
      queryClient.resetQueries();
    }
  } else if (!isUnauthorizedError) {
    captureException(error, { tags: { fn: 'refreshErrorNotUnauthorized' } });
  }
}
