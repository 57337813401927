/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageTemplate_message_template_create } from '../models/MessageTemplate_message_template_create';
import type { MessageTemplate_message_template_list } from '../models/MessageTemplate_message_template_list';
import type { MessageTemplate_message_template_update } from '../models/MessageTemplate_message_template_update';
import type { MessageTemplate_message_template_view } from '../models/MessageTemplate_message_template_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MessageTemplateService {
    /**
     * Retrieves the collection of MessageTemplate resources.
     * Retrieves the collection of MessageTemplate resources.
     * @returns MessageTemplate_message_template_list MessageTemplate collection
     * @throws ApiError
     */
    public static employerListMessageTemplates({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<MessageTemplate_message_template_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/message_templates',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }
    /**
     * Creates a MessageTemplate resource.
     * Creates a MessageTemplate resource.
     * @returns MessageTemplate_message_template_view MessageTemplate resource created
     * @throws ApiError
     */
    public static employerCreateMessageTemplate({
        requestBody,
    }: {
        /**
         * The new MessageTemplate resource
         */
        requestBody: MessageTemplate_message_template_create,
    }): CancelablePromise<MessageTemplate_message_template_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/message_templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Replaces the MessageTemplate resource.
     * Replaces the MessageTemplate resource.
     * @returns MessageTemplate_message_template_view MessageTemplate resource updated
     * @throws ApiError
     */
    public static employerUpdateMessageTemplate({
        uuid,
        requestBody,
    }: {
        /**
         * MessageTemplate identifier
         */
        uuid: string,
        /**
         * The updated MessageTemplate resource
         */
        requestBody: MessageTemplate_message_template_update,
    }): CancelablePromise<MessageTemplate_message_template_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/message_templates/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Removes the MessageTemplate resource.
     * Removes the MessageTemplate resource.
     * @returns void
     * @throws ApiError
     */
    public static employerDeleteMessageTemplate({
        uuid,
    }: {
        /**
         * MessageTemplate identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/employerapi/message_templates/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
