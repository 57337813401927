import {
  useUnreadMessagesEmployer,
  useUnreadMessagesCandidate,
  markAsReadCandidate,
  markAsReadEmployer,
} from 'utils/api';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { TokenType } from 'utils/tokens';
import { useUser } from 'context/UserProvider';

const POLLING_INTERVAL = 5000;

interface UnreadMessagesContextType {
  totalUnreadMessages: number | undefined;
  getUnreadMessagesForConnection: (connectionId?: string) => number | undefined;
  markMessagesAsRead: (connectionId: string, lastReceivedMessageTime?: string) => void;
  refetchUnreadMessages: () => void;
}

const initialState = {
  getUnreadMessagesForConnection: () => 0,
  totalUnreadMessages: 0,
  markMessagesAsRead: () => {
    // markUnread
  },
  refetchUnreadMessages: () => {
    //refetch messages
  },
};

const UnreadMessagesContext = createContext<UnreadMessagesContextType>(initialState);

interface Properties {
  children: ReactNode;
}

export const UnreadMessagesProvider = ({ children }: Properties) => {
  const { tokenType, user } = useUser();

  const { data: unreadMessagesEmployer, refetch: refetchUnreadMessagesEmployer } = useUnreadMessagesEmployer(
    tokenType === TokenType.Employer
  );

  const { data: unreadMessagesCandidate, refetch: refetchUnreadMessagesCandidate } = useUnreadMessagesCandidate(
    tokenType === TokenType.Candidate
  );

  const refetchUnreadMessages = useCallback(() => {
    if (!tokenType) return;
    if (tokenType === TokenType.Candidate) refetchUnreadMessagesCandidate();
    if (tokenType === TokenType.Employer) refetchUnreadMessagesEmployer();
  }, [refetchUnreadMessagesCandidate, refetchUnreadMessagesEmployer, tokenType]);

  useEffect(() => {
    if (!tokenType || !user) return;
    const timeout = setInterval(refetchUnreadMessages, POLLING_INTERVAL);
    return () => clearInterval(timeout);
  }, [refetchUnreadMessages, refetchUnreadMessagesCandidate, tokenType, user]);

  const getUnreadMessagesForConnection = useCallback(
    (connectionId?: string) => {
      if (!tokenType || !connectionId) return;
      if (tokenType === TokenType.Candidate)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return unreadMessagesCandidate ? unreadMessagesCandidate[connectionId] : undefined;
      if (tokenType === TokenType.Employer)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return unreadMessagesEmployer ? unreadMessagesEmployer[connectionId] : undefined;
    },
    [tokenType, unreadMessagesCandidate, unreadMessagesEmployer]
  );

  const totalUnreadMessages = useMemo(() => {
    if (!tokenType) return;
    if (tokenType === TokenType.Candidate) return unreadMessagesCandidate?.total;
    if (tokenType === TokenType.Employer) return unreadMessagesEmployer?.total;
  }, [tokenType, unreadMessagesCandidate?.total, unreadMessagesEmployer?.total]);

  const markMessagesAsRead = useCallback(
    (connectionId: string, lastReceivedMessageTime?: string) => {
      const currentTime = new Date().toISOString();
      if (tokenType === TokenType.Candidate) {
        markAsReadCandidate(connectionId, { until: lastReceivedMessageTime || currentTime });
      }

      if (tokenType === TokenType.Employer) {
        markAsReadEmployer(connectionId, { until: lastReceivedMessageTime || currentTime });
      }
    },
    [tokenType]
  );

  const value = useMemo(
    () => ({
      getUnreadMessagesForConnection,
      totalUnreadMessages,
      markMessagesAsRead,
      refetchUnreadMessages,
    }),
    [getUnreadMessagesForConnection, totalUnreadMessages, markMessagesAsRead, refetchUnreadMessages]
  );

  return <UnreadMessagesContext.Provider value={value}>{children}</UnreadMessagesContext.Provider>;
};

export const useUnreadMessages = (): UnreadMessagesContextType => useContext(UnreadMessagesContext);
