/* eslint-disable no-nested-ternary */
import { QueryClient } from '@tanstack/react-query';
import {
  CANDIDATE_USE_ME_QUERY_KEY,
  EMPLOYER_USE_ME_QUERY_KEY,
  adminRefresh,
  candidateRefresh,
  employerRefresh,
} from 'utils/api';
import { TokenType, getAdminTokens, getTokenTypeForAccessToken, getTokens, saveTokens } from 'utils/tokens';

export async function refreshUser(queryClient: QueryClient, isAdminRoute?: boolean): Promise<void> {
  let response;

  const tokens = isAdminRoute ? getAdminTokens() : getTokens();

  if (!tokens?.refreshToken || !tokens?.accessToken) {
    throw new Error('Missing token');
  }

  const tokenType = getTokenTypeForAccessToken(tokens.accessToken);

  try {
    switch (tokenType) {
      case TokenType.Candidate: {
        response = await candidateRefresh({ refresh_token: tokens.refreshToken });
        break;
      }
      case TokenType.Employer: {
        response = await employerRefresh({ refresh_token: tokens.refreshToken });
        break;
      }
      case TokenType.Admin: {
        response = await adminRefresh(tokens.refreshToken);
        break;
      }
      default: {
        throw new Error('Unknown token type');
      }
    }
  } catch {
    return;
  }

  if (!response?.token || !response?.refresh_token) {
    throw new Error('Missing token or refresh token in response');
  }

  saveTokens(response.token, response.refresh_token);

  try {
    await queryClient.refetchQueries({
      queryKey:
        tokenType === TokenType.Candidate
          ? [CANDIDATE_USE_ME_QUERY_KEY]
          : tokenType === TokenType.Employer
            ? [EMPLOYER_USE_ME_QUERY_KEY]
            : undefined,
    });
  } catch {
    throw new Error('Error in refetch');
  }
}
