/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Connection_jsonld = {
    readonly '@id': string;
    readonly '@type': string;
    readonly id: number;
    readonly uuid: string;
    status: Connection_jsonld.status;
    shareStatus: Connection_jsonld.shareStatus;
    readonly createdAt: string;
    updatedAt: string;
    candidateUpdatedAt: string | null;
    employerUpdatedAt: string | null;
    readonly lastSeenByCandidate: string | null;
    readonly lastSeenByEmployer: string | null;
    lastEmailNotificationSentAt: string | null;
    lastWhatsAppNotificationSentAt: string | null;
    candidate: string;
    employer: string;
};
export namespace Connection_jsonld {
    export enum status {
        CONCEPT = 'concept',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELED = 'canceled',
    }
    export enum shareStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
    }
}

