/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Feedback = {
    readonly id: number;
    readonly createdAt: string;
    userType: Feedback.userType;
    feedbackReason: Feedback.feedbackReason;
    content: string;
};
export namespace Feedback {
    export enum userType {
        CANDIDATE = 'Candidate',
        EMPLOYER = 'Employer',
        UNKNOWN = 'Unknown',
    }
    export enum feedbackReason {
        PROFILE_REMOVAL = 'ProfileRemoval',
    }
}

