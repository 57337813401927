/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Employer_jsonld_employer_admin_view = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly uuid: string;
    prefix: string;
    accountStatus: Employer_jsonld_employer_admin_view.accountStatus;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    city: string;
};
export namespace Employer_jsonld_employer_admin_view {
    export enum accountStatus {
        DEMO = 'demo',
        FULL = 'full',
        SUMMER_DEAL = 'summer-deal',
    }
}

