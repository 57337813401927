import Cookies from 'js-cookie';

const LOGIN_REDIRECT_KEY = 'JOBQ_LOGIN_REDIRECT';

export const setLoginRedirect = (value: string) => {
  Cookies.set(LOGIN_REDIRECT_KEY, value, {
    expires: 2,
  });
};

const clearLoginRedirect = () => {
  Cookies.remove(LOGIN_REDIRECT_KEY);
};

export const getLoginRedirect = (clean = true) => {
  const redirectFromCookie = Cookies.get(LOGIN_REDIRECT_KEY);
  //We always clear the cookie after reading it
  if (clean) {
    clearLoginRedirect();
  }
  return redirectFromCookie;
};
