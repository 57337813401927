export const HEADER_LABELS = {
  publicEmployer: 'Werkgever',
  publicCandidate: 'Werknemer',
  employerSettings: 'Instellingen',
  employerSearchPage: 'Zoekopdrachten',
  employerChatPage: 'Chats',
  profilePage: 'Profiel',
  chatPage: 'Gesprekken',
  logout: 'Uitloggen',
};
