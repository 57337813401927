import { Loader } from 'components/Loader';
import { ROUTES } from 'constants/ROUTES';
import { useUser } from 'context/UserProvider';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { setLoginRedirect } from 'utils/loginRedirect';
import { TokenType } from 'utils/tokens';
import { ProtectApplicationFromUnwantedUsersProperties } from '../pages/_app';

export const ProtectApplicationFromUnwantedUsers = ({
  children,
  isPublicPage,
  shouldBeEmployer,
}: ProtectApplicationFromUnwantedUsersProperties) => {
  const { isLoadingUser, user, tokenType } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    // If we try to access public pages, thats fine.
    if (isPublicPage) {
      return;
    }

    // If we try to access non-public pages without a user, redirect to login.
    if (!isPublicPage && !user) {
      if (router.asPath.includes('/uitloggen')) {
        return;
      }

      //if candidate page, redirect to candidate login and set cookie
      if (!router.asPath.includes('/werkgever')) {
        setLoginRedirect(router.asPath);
        router.replace(ROUTES.inloggen);
        return;
      }

      //otherwise set query parameter to store redirect url
      router.replace(`${ROUTES.werkgeverInloggen}?login_redirect=${router.asPath}`);
      return;
    }

    // If we try to access an employer page as a non-employer, redirect to employer home and vice versa.
    if (shouldBeEmployer && tokenType !== TokenType.Employer) {
      router.replace(ROUTES.gesprekken);
    } else if (!shouldBeEmployer && tokenType === TokenType.Employer) {
      router.replace(ROUTES.werkgeverZoekopdrachten);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser, isPublicPage, shouldBeEmployer, tokenType, user]);

  if (isPublicPage) {
    return children;
  }

  if (isLoadingUser && !user) {
    return <Loader />;
  }

  if (!isLoadingUser && !user) {
    return;
  }

  return children;
};
