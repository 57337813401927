import { CaptureContext } from '@sentry/types';
import { captureException as Sentry_captureException } from '@sentry/nextjs';

export function captureException(error: unknown, captureContext?: CaptureContext) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error, captureContext);
  }

  Sentry_captureException(error, captureContext);
}
