/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Impersonation } from '../models/Impersonation';
import type { JWTResponse } from '../models/JWTResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ImpersonationService {
    /**
     * Impersonate an employer with the given email address
     * Impersonate an employer with the given email address
     * @returns JWTResponse JWT and refresh token to be able to impersonate the employer
     * @throws ApiError
     */
    public static adminImpersonateEmployer({
        requestBody,
    }: {
        /**
         * The new Impersonation resource
         */
        requestBody: Impersonation,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminapi/impersonate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `When an employer with the given email address could not be found`,
            },
        });
    }
    /**
     * Impersonate a candidate with the given email address
     * Impersonate a candidate with the given email address
     * @returns JWTResponse JWT and refresh token to be able to impersonate the candidate
     * @throws ApiError
     */
    public static adminImpersonateCandidate({
        requestBody,
    }: {
        /**
         * The new Impersonation resource
         */
        requestBody: Impersonation,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminapi/impersonate_candidate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `When a candidate with the given email address could not be found`,
            },
        });
    }
}
