/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Sector_sector_view } from '../models/Sector_sector_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SectorService {
    /**
     * Retrieves the collection of Sector resources.
     * Retrieves the collection of Sector resources.
     * @returns Sector_sector_view Sector collection
     * @throws ApiError
     */
    public static listSectors({
        page = 1,
        itemsPerPage = 30,
        isco08,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        isco08?: string,
    }): CancelablePromise<Array<Sector_sector_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sectors',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'isco08': isco08,
            },
        });
    }
    /**
     * Retrieves a Sector resource.
     * Retrieves a Sector resource.
     * @returns Sector_sector_view Sector resource
     * @throws ApiError
     */
    public static viewSector({
        code,
    }: {
        /**
         * Sector identifier
         */
        code: string,
    }): CancelablePromise<Sector_sector_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sectors/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
