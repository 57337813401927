/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employer_employer_view_self } from '../models/Employer_employer_view_self';
import type { JWTAuth } from '../models/JWTAuth';
import type { JWTRefresh } from '../models/JWTRefresh';
import type { JWTResponse } from '../models/JWTResponse';
import type { Request } from '../models/Request';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthenticationService {
    /**
     * Refresh the JWT using a refresh token
     * Refresh the JWT using a refresh token
     * @returns JWTResponse Refresh successful
     * @throws ApiError
     */
    public static jwtRefresh({
        requestBody,
    }: {
        /**
         * Refresh token
         */
        requestBody: JWTRefresh,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminapi/jwt/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `An authentication exception occurred: incorrect or expired refresh token`,
            },
        });
    }
    /**
     * Log in and create a JWT
     * Log in and create a JWT
     * @returns JWTResponse Login successful
     * @throws ApiError
     */
    public static jwtToken({
        requestBody,
    }: {
        /**
         * Credentials
         */
        requestBody: JWTAuth,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/adminapi/jwt/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `Invalid credentials: user does not exist or password is incorrect`,
            },
        });
    }
    /**
     * Log in and create a JWT
     * Log in and create a JWT
     * @returns JWTResponse Login successful
     * @throws ApiError
     */
    public static apiCandidatesLoginCheck({
        user,
        expires,
        hash,
    }: {
        user: any,
        expires: any,
        hash: any,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/jwt/check_link',
            query: {
                'user': user,
                'expires': expires,
                'hash': hash,
            },
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Refresh the JWT using a refresh token
     * Refresh the JWT using a refresh token
     * @returns JWTResponse Refresh successful
     * @throws ApiError
     */
    public static jwtRefresh1({
        requestBody,
    }: {
        /**
         * Refresh token
         */
        requestBody: JWTRefresh,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/jwt/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `An authentication exception occurred: incorrect or expired refresh token`,
            },
        });
    }
    /**
     * Request a login link
     * Send a login link if the user is found
     * @returns string Created
     * @throws ApiError
     */
    public static candidateSendLoginLink({
        requestBody,
    }: {
        /**
         * The new Request resource
         */
        requestBody: Request,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/jwt/request_link',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Refresh the JWT using a refresh token
     * Refresh the JWT using a refresh token
     * @returns JWTResponse Refresh successful
     * @throws ApiError
     */
    public static jwtRefresh2({
        requestBody,
    }: {
        /**
         * Refresh token
         */
        requestBody: JWTRefresh,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/jwt/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `An authentication exception occurred: incorrect or expired refresh token`,
            },
        });
    }
    /**
     * Log in and create a JWT
     * Log in and create a JWT
     * @returns JWTResponse Login successful
     * @throws ApiError
     */
    public static jwtToken1({
        requestBody,
    }: {
        /**
         * Credentials
         */
        requestBody: JWTAuth,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/jwt/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `Invalid credentials: user does not exist or password is incorrect`,
            },
        });
    }
    /**
     * View yourself
     * View your own information as employer
     * @returns Employer_employer_view_self Employer resource
     * @throws ApiError
     */
    public static employerViewMyself(): CancelablePromise<Employer_employer_view_self> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
