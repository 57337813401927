/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Candidate_candidate_admin_list } from '../models/Candidate_candidate_admin_list';
import type { Candidate_candidate_admin_view } from '../models/Candidate_candidate_admin_view';
import type { Candidate_candidate_list } from '../models/Candidate_candidate_list';
import type { Candidate_candidate_update_self } from '../models/Candidate_candidate_update_self';
import type { Candidate_candidate_view } from '../models/Candidate_candidate_view';
import type { Candidate_candidate_view_self } from '../models/Candidate_candidate_view_self';
import type { CandidateActivation } from '../models/CandidateActivation';
import type { CandidateEmailChange_candidate_email_change_request_create } from '../models/CandidateEmailChange_candidate_email_change_request_create';
import type { CandidateRegistration } from '../models/CandidateRegistration';
import type { ChangeEmailConfirmation_candidate_email_change_confirmation_create } from '../models/ChangeEmailConfirmation_candidate_email_change_confirmation_create';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CandidateService {
    /**
     * Retrieves the collection of Candidate resources.
     * Retrieves the collection of Candidate resources.
     * @returns Candidate_candidate_admin_list Candidate collection
     * @throws ApiError
     */
    public static adminListCandidates({
        page = 1,
        itemsPerPage = 30,
        email,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        email?: string,
    }): CancelablePromise<Array<Candidate_candidate_admin_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/adminapi/candidates',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'email': email,
            },
        });
    }
    /**
     * Retrieves a Candidate resource.
     * Retrieves a Candidate resource.
     * @returns Candidate_candidate_admin_view Candidate resource
     * @throws ApiError
     */
    public static adminViewCandidate({
        uuid,
    }: {
        /**
         * Candidate identifier
         */
        uuid: string,
    }): CancelablePromise<Candidate_candidate_admin_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/adminapi/candidates/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Request an email change
     * Request an email change
     * @returns string Created
     * @throws ApiError
     */
    public static candidateChangeEmail({
        requestBody,
    }: {
        /**
         * The new CandidateEmailChange resource
         */
        requestBody: CandidateEmailChange_candidate_email_change_request_create,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/candidates/change_email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
            },
        });
    }
    /**
     * Confirms your change to a new e-mailadres
     * Confirm your new e-mailadres
     * @returns any ok
     * @throws ApiError
     */
    public static candidateChangeEmailConfirm({
        requestBody,
    }: {
        /**
         * The new ChangeEmailConfirmation resource
         */
        requestBody: ChangeEmailConfirmation_candidate_email_change_confirmation_create,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/candidates/change_email/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }
    /**
     * Register a new candidate user
     * Registers a new candidate user
     * @returns string Created
     * @throws ApiError
     */
    public static candidateRegister({
        requestBody,
    }: {
        /**
         * The new CandidateRegistration resource
         */
        requestBody: CandidateRegistration,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/candidates/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
            },
        });
    }
    /**
     * Activate a new candidate user
     * Activates a new candidate user
     * @returns any Created
     * @throws ApiError
     */
    public static candidateActivate({
        requestBody,
    }: {
        /**
         * The new CandidateActivation resource
         */
        requestBody: CandidateActivation,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/candidateapi/candidates/register/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }
    /**
     * Replaces the Candidate resource.
     * Replaces the Candidate resource.
     * @returns Candidate_candidate_view_self Candidate resource updated
     * @throws ApiError
     */
    public static candidateUpdateMyself({
        uuid,
        requestBody,
    }: {
        /**
         * Candidate identifier
         */
        uuid: string,
        /**
         * The updated Candidate resource
         */
        requestBody: Candidate_candidate_update_self,
    }): CancelablePromise<Candidate_candidate_view_self> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/candidates/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * View yourself
     * View your own information as candidate
     * @returns Candidate_candidate_view_self Candidate resource
     * @throws ApiError
     */
    public static candidateMe(): CancelablePromise<Candidate_candidate_view_self> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Removes the Candidate resource.
     * Removes the Candidate resource.
     * @returns void
     * @throws ApiError
     */
    public static candidateDeleteMyself(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/candidateapi/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Retrieves the collection of Candidate resources.
     * Retrieves the collection of Candidate resources.
     * @returns Candidate_candidate_list Candidate collection
     * @throws ApiError
     */
    public static employerListCandidates({
        page = 1,
        itemsPerPage = 30,
        isco08,
        sectorsAllSalaryBetween,
        sectorsAllSalaryGt,
        sectorsAllSalaryGte,
        sectorsAllSalaryLt,
        sectorsAllSalaryLte,
        sectorsAllYearsOfWorkExperienceBetween,
        sectorsAllYearsOfWorkExperienceGt,
        sectorsAllYearsOfWorkExperienceGte,
        sectorsAllYearsOfWorkExperienceLt,
        sectorsAllYearsOfWorkExperienceLte,
        educationLevelPositionBetween,
        educationLevelPositionGt,
        educationLevelPositionGte,
        educationLevelPositionLt,
        educationLevelPositionLte,
        sectorsAllHoursPerWeekBetween,
        sectorsAllHoursPerWeekGt,
        sectorsAllHoursPerWeekGte,
        sectorsAllHoursPerWeekLt,
        sectorsAllHoursPerWeekLte,
        sectorsTeacherSubjectsArray,
        orderCreatedAt,
        orderSectorsAllSalary,
        orderSectorsAllHoursPerWeek,
        orderScore,
        locationLatitude,
        locationLongitude,
        locationRadius,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        isco08?: string,
        sectorsAllSalaryBetween?: string,
        sectorsAllSalaryGt?: string,
        sectorsAllSalaryGte?: string,
        sectorsAllSalaryLt?: string,
        sectorsAllSalaryLte?: string,
        sectorsAllYearsOfWorkExperienceBetween?: string,
        sectorsAllYearsOfWorkExperienceGt?: string,
        sectorsAllYearsOfWorkExperienceGte?: string,
        sectorsAllYearsOfWorkExperienceLt?: string,
        sectorsAllYearsOfWorkExperienceLte?: string,
        educationLevelPositionBetween?: string,
        educationLevelPositionGt?: string,
        educationLevelPositionGte?: string,
        educationLevelPositionLt?: string,
        educationLevelPositionLte?: string,
        sectorsAllHoursPerWeekBetween?: string,
        sectorsAllHoursPerWeekGt?: string,
        sectorsAllHoursPerWeekGte?: string,
        sectorsAllHoursPerWeekLt?: string,
        sectorsAllHoursPerWeekLte?: string,
        sectorsTeacherSubjectsArray?: Array<string>,
        orderCreatedAt?: 'asc' | 'desc',
        orderSectorsAllSalary?: 'asc' | 'desc',
        orderSectorsAllHoursPerWeek?: 'asc' | 'desc',
        orderScore?: 'asc' | 'desc',
        locationLatitude?: number,
        locationLongitude?: number,
        locationRadius?: number,
    }): CancelablePromise<Array<Candidate_candidate_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/candidates',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'isco08': isco08,
                'sectors.all.salary[between]': sectorsAllSalaryBetween,
                'sectors.all.salary[gt]': sectorsAllSalaryGt,
                'sectors.all.salary[gte]': sectorsAllSalaryGte,
                'sectors.all.salary[lt]': sectorsAllSalaryLt,
                'sectors.all.salary[lte]': sectorsAllSalaryLte,
                'sectors.all.yearsOfWorkExperience[between]': sectorsAllYearsOfWorkExperienceBetween,
                'sectors.all.yearsOfWorkExperience[gt]': sectorsAllYearsOfWorkExperienceGt,
                'sectors.all.yearsOfWorkExperience[gte]': sectorsAllYearsOfWorkExperienceGte,
                'sectors.all.yearsOfWorkExperience[lt]': sectorsAllYearsOfWorkExperienceLt,
                'sectors.all.yearsOfWorkExperience[lte]': sectorsAllYearsOfWorkExperienceLte,
                'educationLevel.position[between]': educationLevelPositionBetween,
                'educationLevel.position[gt]': educationLevelPositionGt,
                'educationLevel.position[gte]': educationLevelPositionGte,
                'educationLevel.position[lt]': educationLevelPositionLt,
                'educationLevel.position[lte]': educationLevelPositionLte,
                'sectors.all.hoursPerWeek[between]': sectorsAllHoursPerWeekBetween,
                'sectors.all.hoursPerWeek[gt]': sectorsAllHoursPerWeekGt,
                'sectors.all.hoursPerWeek[gte]': sectorsAllHoursPerWeekGte,
                'sectors.all.hoursPerWeek[lt]': sectorsAllHoursPerWeekLt,
                'sectors.all.hoursPerWeek[lte]': sectorsAllHoursPerWeekLte,
                'sectors.teacher.subjects[]': sectorsTeacherSubjectsArray,
                'order[createdAt]': orderCreatedAt,
                'order[sectors.all.salary]': orderSectorsAllSalary,
                'order[sectors.all.hoursPerWeek]': orderSectorsAllHoursPerWeek,
                'order[score]': orderScore,
                'location[latitude]': locationLatitude,
                'location[longitude]': locationLongitude,
                'location[radius]': locationRadius,
            },
        });
    }
    /**
     * Retrieves a Candidate resource.
     * Retrieves a Candidate resource.
     * @returns Candidate_candidate_view Candidate resource
     * @throws ApiError
     */
    public static employerViewCandidate({
        uuid,
    }: {
        /**
         * Candidate identifier
         */
        uuid: string,
    }): CancelablePromise<Candidate_candidate_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/candidates/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
