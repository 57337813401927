import React from 'react';

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 105 50">
    <g clipPath="url(#clip0_5_5281)">
      <path
        fill="#000033"
        d="M22.3 13.6A11.2 11.2 0 0 0 16 34a9 9 0 0 1 12.8 0c3-2 4.8-5.4 4.8-9.3 0-6.2-5-11.2-11.2-11.2Zm0 15c-2.5 0-4.6-2-4.6-4.5a4.6 4.6 0 1 1 9.2 0c0 2.5-2 4.5-4.6 4.5Z"
      />
      <path
        fill="url(#paint0_linear_5_5281)"
        d="M97.6 38.5a4.7 4.7 0 0 1-4.2-6.8A15.4 15.4 0 0 0 68.8 14a4.7 4.7 0 0 1-6.6-6.6A24.7 24.7 0 0 1 101.7 36a4.6 4.6 0 0 1-4.1 2.5Z"
      />
      <path
        fill="url(#paint1_linear_5_5281)"
        d="m104 42.7-1.8-9.7a4.7 4.7 0 1 0-9.2 1.7l.7 3.7L89 38a5 5 0 0 0-2.4.5 15.3 15.3 0 0 1-18-2.8c-6-6-6-15.8 0-21.9A4.7 4.7 0 0 0 62 7.2a24.6 24.6 0 0 0-5.7 9.2 13 13 0 0 1 0 16.7 24.7 24.7 0 0 0 33.3 14.3l9.3.8a5 5 0 0 0 3.8-1.6c1-1 1.4-2.5 1.1-4Z"
      />
      <path
        fill="#000033"
        d="M35.1 5.1h5.7v10a9 9 0 0 1 5.5-1.6 11.2 11.2 0 0 1 0 22.5c-6.2 0-11.2-5-11.2-11.2V5Zm5.6 19.7c0 3.2 2.4 5.7 5.6 5.7 3.3 0 5.6-2.5 5.6-5.7 0-3.3-2.3-5.8-5.6-5.8a5.5 5.5 0 0 0-5.6 5.8Z"
      />
      <path fill="#000033" d="M0 30.5c2.8 0 4-1.4 4-5V5.2h5.6v20.5C9.6 32.9 5.6 36 0 36v-5.5Z" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5_5281"
        x1="82.6312"
        x2="93.9229"
        y1="2.17803"
        y2="30.5427"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B8FC2D" />
        <stop offset="1" stopColor="#EBFFC2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5_5281"
        x1="68.3551"
        x2="52.359"
        y1="49.6212"
        y2="12.825"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#4DE4FF" />
        <stop offset="1" stopColor="#D3F8FF" />
      </linearGradient>
      <clipPath id="clip0_5_5281">
        <rect width="105" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
