/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Banner_banner_view } from '../models/Banner_banner_view';
import type { Banner_ImageInput } from '../models/Banner_ImageInput';
import type { Credentials } from '../models/Credentials';
import type { Employer_employer_admin_list } from '../models/Employer_employer_admin_list';
import type { Employer_employer_admin_update } from '../models/Employer_employer_admin_update';
import type { Employer_employer_admin_view } from '../models/Employer_employer_admin_view';
import type { Employer_employer_update_self } from '../models/Employer_employer_update_self';
import type { Employer_employer_view } from '../models/Employer_employer_view';
import type { Employer_employer_view_others } from '../models/Employer_employer_view_others';
import type { Employer_employer_view_self } from '../models/Employer_employer_view_self';
import type { EmployerActivation } from '../models/EmployerActivation';
import type { EmployerRegistration } from '../models/EmployerRegistration';
import type { Logo_ImageInput } from '../models/Logo_ImageInput';
import type { Logo_logo_view } from '../models/Logo_logo_view';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployerService {
    /**
     * Retrieves the collection of Employer resources.
     * Retrieves the collection of Employer resources.
     * @returns Employer_employer_admin_list Employer collection
     * @throws ApiError
     */
    public static adminListEmployers({
        page = 1,
        itemsPerPage = 30,
        email,
        companyName,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        email?: string,
        companyName?: string,
    }): CancelablePromise<Array<Employer_employer_admin_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/adminapi/employers',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'email': email,
                'companyName': companyName,
            },
        });
    }
    /**
     * Retrieves a Employer resource.
     * Retrieves a Employer resource.
     * @returns Employer_employer_admin_view Employer resource
     * @throws ApiError
     */
    public static adminViewEmployer({
        uuid,
    }: {
        /**
         * Employer identifier
         */
        uuid: string,
    }): CancelablePromise<Employer_employer_admin_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/adminapi/employers/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Replaces the Employer resource.
     * Replaces the Employer resource.
     * @returns Employer_employer_admin_view Employer resource updated
     * @throws ApiError
     */
    public static adminUpdateEmployer({
        uuid,
        requestBody,
    }: {
        /**
         * Employer identifier
         */
        uuid: string,
        /**
         * The updated Employer resource
         */
        requestBody: Employer_employer_admin_update,
    }): CancelablePromise<Employer_employer_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/adminapi/employers/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Employer resource.
     * Retrieves a Employer resource.
     * @returns Employer_employer_view Employer resource
     * @throws ApiError
     */
    public static candidateViewEmployer({
        uuid,
    }: {
        /**
         * Employer identifier
         */
        uuid: string,
    }): CancelablePromise<Employer_employer_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/candidateapi/employers/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Register a new user
     * Registers a new user
     * @returns string Created
     * @throws ApiError
     */
    public static employerRegister({
        requestBody,
    }: {
        /**
         * The new EmployerRegistration resource
         */
        requestBody: EmployerRegistration,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/employers/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }
    /**
     * Activate a new user
     * Activates a new user
     * @returns any Created
     * @throws ApiError
     */
    public static employerActivate({
        requestBody,
    }: {
        /**
         * The new EmployerActivation resource
         */
        requestBody: EmployerActivation,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/employers/register/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }
    /**
     * Request a password reset
     * Generate a password reset token and e-mail the code/link to the user
     * @returns string Created
     * @throws ApiError
     */
    public static employerRequestPasswordReset({
        requestBody,
    }: {
        /**
         * The new PasswordResetRequest resource
         */
        requestBody: PasswordResetRequest,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/employers/reset_password/request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reset password
     * Reset the password to the given password, using a valid token
     * @returns string Created
     * @throws ApiError
     */
    public static employerConfirmPasswordReset({
        requestBody,
    }: {
        /**
         * The new PasswordReset resource
         */
        requestBody: PasswordReset,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/employers/reset_password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Update your credentials
     * Update your credentials (email and/or password)
     * @returns any Ok
     * @throws ApiError
     */
    public static employerUpdateCredentials({
        requestBody,
    }: {
        /**
         * The new Credentials resource
         */
        requestBody: Credentials,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/employers/update_credentials',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                403: `Invalid password`,
            },
        });
    }
    /**
     * Retrieves a Employer resource.
     * Retrieves a Employer resource.
     * @returns Employer_employer_view_others Employer resource
     * @throws ApiError
     */
    public static employerViewEmployer({
        uuid,
    }: {
        /**
         * Employer identifier
         */
        uuid: string,
    }): CancelablePromise<Employer_employer_view_others> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/employers/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Replaces the Employer resource.
     * Replaces the Employer resource.
     * @returns Employer_employer_view_self Employer resource updated
     * @throws ApiError
     */
    public static employerUpdateMyself({
        uuid,
        requestBody,
    }: {
        /**
         * Employer identifier
         */
        uuid: string,
        /**
         * The updated Employer resource
         */
        requestBody: Employer_employer_update_self,
    }): CancelablePromise<Employer_employer_view_self> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/employers/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Upload your banner
     * Upload your bannner
     * @returns Banner_banner_view Banner resource updated
     * @throws ApiError
     */
    public static employerUploadBanner({
        requestBody,
    }: {
        /**
         * The updated Banner resource
         */
        requestBody: Banner_ImageInput,
    }): CancelablePromise<Banner_banner_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/me/banner',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Upload your logo
     * Upload your logo
     * @returns Logo_logo_view Logo resource updated
     * @throws ApiError
     */
    public static employerUploadLogo({
        requestBody,
    }: {
        /**
         * The updated Logo resource
         */
        requestBody: Logo_ImageInput,
    }): CancelablePromise<Logo_logo_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/me/logo',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
}
