/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Search_search_create } from '../models/Search_search_create';
import type { Search_search_update } from '../models/Search_search_update';
import type { Search_search_view } from '../models/Search_search_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SearchService {
    /**
     * Retrieves the collection of Search resources.
     * Retrieves the collection of Search resources.
     * @returns Search_search_view Search collection
     * @throws ApiError
     */
    public static employerListSearches({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Search_search_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/searches',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }
    /**
     * Creates a Search resource.
     * Creates a Search resource.
     * @returns Search_search_view Search resource created
     * @throws ApiError
     */
    public static employerCreateSearch({
        requestBody,
    }: {
        /**
         * The new Search resource
         */
        requestBody: Search_search_create,
    }): CancelablePromise<Search_search_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/searches',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Search resource.
     * Retrieves a Search resource.
     * @returns Search_search_view Search resource
     * @throws ApiError
     */
    public static employerViewSearch({
        id,
    }: {
        /**
         * Search identifier
         */
        id: string,
    }): CancelablePromise<Search_search_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/searches/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Replaces the Search resource.
     * Replaces the Search resource.
     * @returns Search_search_view Search resource updated
     * @throws ApiError
     */
    public static employerUpdateSearch({
        id,
        requestBody,
    }: {
        /**
         * Search identifier
         */
        id: string,
        /**
         * The updated Search resource
         */
        requestBody: Search_search_update,
    }): CancelablePromise<Search_search_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/searches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Removes the Search resource.
     * Removes the Search resource.
     * @returns void
     * @throws ApiError
     */
    public static employerDeleteSearch({
        id,
    }: {
        /**
         * Search identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/employerapi/searches/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
