export const trackPageView = (url: URL) => {
  if (typeof window === 'undefined' || !window.gtag || !process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('trackPageView', url);
  }

  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID, {
    page_location: url,
    anonymize_ip: true,
  });
};

export const trackEvent = (name: string, parameters?: Record<string, string | number>) => {
  if (typeof window === 'undefined' || !window.gtag || !process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('trackEvent', name, parameters);
  }

  window.gtag('event', name, parameters);
};
