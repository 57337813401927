/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Employer_employer_admin_update = {
    accountStatus: Employer_employer_admin_update.accountStatus;
};
export namespace Employer_employer_admin_update {
    export enum accountStatus {
        DEMO = 'demo',
        FULL = 'full',
        SUMMER_DEAL = 'summer-deal',
    }
}

