/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Feedback_jsonld = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly id: number;
    readonly createdAt: string;
    userType: Feedback_jsonld.userType;
    feedbackReason: Feedback_jsonld.feedbackReason;
    content: string;
};
export namespace Feedback_jsonld {
    export enum userType {
        CANDIDATE = 'Candidate',
        EMPLOYER = 'Employer',
        UNKNOWN = 'Unknown',
    }
    export enum feedbackReason {
        PROFILE_REMOVAL = 'ProfileRemoval',
    }
}

