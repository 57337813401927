/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Gender_gender_view } from '../models/Gender_gender_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GenderService {
    /**
     * Retrieves the collection of Gender resources.
     * Retrieves the collection of Gender resources.
     * @returns Gender_gender_view Gender collection
     * @throws ApiError
     */
    public static listGenders({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Gender_gender_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/genders',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }
    /**
     * Retrieves a Gender resource.
     * Retrieves a Gender resource.
     * @returns Gender_gender_view Gender resource
     * @throws ApiError
     */
    public static viewGender({
        id,
    }: {
        /**
         * Gender identifier
         */
        id: string,
    }): CancelablePromise<Gender_gender_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/genders/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
