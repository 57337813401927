/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationLevel_education_level_view } from '../models/EducationLevel_education_level_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EducationLevelService {
    /**
     * Retrieves the collection of EducationLevel resources.
     * Retrieves the collection of EducationLevel resources.
     * @returns EducationLevel_education_level_view EducationLevel collection
     * @throws ApiError
     */
    public static listEducationLevels({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<EducationLevel_education_level_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/education_levels',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }
    /**
     * Retrieves a EducationLevel resource.
     * Retrieves a EducationLevel resource.
     * @returns EducationLevel_education_level_view EducationLevel resource
     * @throws ApiError
     */
    public static viewEducationLevels({
        id,
    }: {
        /**
         * EducationLevel identifier
         */
        id: string,
    }): CancelablePromise<EducationLevel_education_level_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/education_levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
