/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Connection_connection_employer } from '../models/Connection_connection_employer';
import type { Connection_MarkAsReadRequest } from '../models/Connection_MarkAsReadRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConnectionService {
    /**
     * Replaces the Connection resource.
     * Replaces the Connection resource.
     * @returns Connection_connection_employer Connection resource updated
     * @throws ApiError
     */
    public static candidateMarkConnectionAsRead({
        uuid,
        requestBody,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
        /**
         * The updated Connection resource
         */
        requestBody: Connection_MarkAsReadRequest,
    }): CancelablePromise<Connection_connection_employer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/candidateapi/chat_connections/{uuid}/mark_as_read',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Replaces the Connection resource.
     * Replaces the Connection resource.
     * @returns Connection_connection_employer Connection resource updated
     * @throws ApiError
     */
    public static employerMarkConnectionAsRead({
        uuid,
        requestBody,
    }: {
        /**
         * Connection identifier
         */
        uuid: string,
        /**
         * The updated Connection resource
         */
        requestBody: Connection_MarkAsReadRequest,
    }): CancelablePromise<Connection_connection_employer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/chat_connections/{uuid}/mark_as_read',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
}
