import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './RichText.module.css';

interface Properties {
  children: ReactNode;
  center?: boolean;
}

export const RichText = ({ children, center }: Properties) => {
  const className = classNames([styles.container, { [styles.center]: center }]);
  return <div className={className}>{children}</div>;
};
