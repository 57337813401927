import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './FlexRow.module.css';

interface Properties {
  alignCenter?: boolean;
  children: ReactNode;
  mobileReversed?: boolean;
  reversed?: boolean;
}

export const FlexRow = ({ alignCenter, children, mobileReversed, reversed }: Properties) => {
  const className = cx(styles.container, {
    [styles.alignCenter]: alignCenter,
    [styles.mobileReversed]: mobileReversed,
    [styles.reversed]: reversed,
  });
  return <div className={className}>{children}</div>;
};
