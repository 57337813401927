import { faComments } from '@fortawesome/pro-regular-svg-icons/faComments';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Logo } from './Logo';
import { ROUTES } from 'constants/ROUTES';
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import Link from 'next/link';
import styles from './PageHeader.module.css';
import { useUser } from 'context/UserProvider';
import { HEADER_LABELS } from 'constants/HEADER_LABELS';
import { TokenType } from 'utils/tokens';
import { useUnreadMessages } from 'context/UnreadMessagesProvider';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';
import { hideHeaderTrigger, showHeaderTrigger } from 'utils/toggleShowHeader';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';

export const PAGE_HEADER_ID = 'page-header-container';
interface MenuItem {
  href: string;
  icon?: IconDefinition;
  label: string;
}

const MENUS: Record<'public' | 'candidate' | 'employer', MenuItem[]> = {
  public: [
    { href: ROUTES.werknemerHome, label: HEADER_LABELS.publicCandidate },
    { href: ROUTES.werkgeverHome, label: HEADER_LABELS.publicEmployer },
  ],
  candidate: [
    { href: ROUTES.gesprekken, icon: faComments, label: HEADER_LABELS.chatPage },
    { href: ROUTES.profiel, icon: faUser, label: HEADER_LABELS.profilePage },
    { href: ROUTES.uitloggen, icon: faPowerOff, label: HEADER_LABELS.logout },
  ],
  employer: [
    { href: ROUTES.werkgeverZoekopdrachten, icon: faMagnifyingGlass, label: HEADER_LABELS.employerSearchPage },
    { href: ROUTES.werkgeverGesprekken, icon: faComments, label: HEADER_LABELS.employerChatPage },
    { href: ROUTES.werkgeverInstellingen, icon: faGear, label: HEADER_LABELS.employerSettings },
    { href: ROUTES.uitloggen, icon: faPowerOff, label: HEADER_LABELS.logout },
  ],
};

export interface PageHeaderProperties {
  isPublic?: boolean;
  transparent?: boolean;
  positionAbsolute?: boolean;
}

export const PageHeader = ({ isPublic, transparent, positionAbsolute }: PageHeaderProperties) => {
  const { pathname, asPath } = useRouter();
  const { user, tokenType } = useUser();
  const router = useRouter();

  const { totalUnreadMessages } = useUnreadMessages();

  const [menuOpen, setMenuOpen] = useState(false);

  const menuItems = useMemo(() => {
    if (!user || isPublic) {
      return MENUS.public;
    }

    if (pathname.includes(ROUTES.werkgeverHome)) {
      return MENUS.employer;
    }

    return MENUS.candidate;
  }, [isPublic, pathname, user]);

  const toggleMenu = useCallback(() => {
    setMenuOpen((previousMenuOpen) => !previousMenuOpen);
  }, []);

  useEffect(() => setMenuOpen(false), [router]);

  useEffect(() => {
    const pageHeader = document.querySelector(`#${PAGE_HEADER_ID}`) as HTMLDivElement;

    const hideHeader = () => {
      pageHeader.style.display = 'none';
    };

    const showHeader = () => {
      pageHeader.style.display = 'flex';
    };

    window.addEventListener(hideHeaderTrigger, hideHeader);
    window.addEventListener(showHeaderTrigger, showHeader);

    return () => {
      window.removeEventListener(hideHeaderTrigger, hideHeader);
      window.removeEventListener(showHeaderTrigger, showHeader);
    };
  }, [router.asPath]);

  const homeLink = useMemo(() => {
    const isEmployerPage = asPath.includes('/werkgever');
    if (user) {
      if (isEmployerPage) {
        return ROUTES.werkgeverGesprekken;
      }
      return ROUTES.gesprekken;
    }

    if (isEmployerPage) {
      return ROUTES.werkgeverHome;
    }
    return ROUTES.werknemerHome;
  }, [asPath, user]);

  return (
    <div
      id={PAGE_HEADER_ID}
      className={cx(styles.container, {
        [styles.transparent]: transparent,
        [styles.positionAbsolute]: positionAbsolute,
      })}>
      <Link href={homeLink} className={styles.logo}>
        <Logo />
      </Link>

      <nav className={cx(styles.navigation, { [styles.isOpen]: menuOpen })}>
        <ul>
          {menuItems.map(({ label, href, icon }) => (
            <li key={href}>
              <Link href={href}>
                <span className={cx(styles.navigationLink, { [styles.active]: isMenuItemActive(href, pathname) })}>
                  {!!icon && <FontAwesomeIcon icon={icon} />}
                  <span>{label}</span>
                  {label === HEADER_LABELS.chatPage && !!totalUnreadMessages && totalUnreadMessages > 0 ? (
                    <div className={styles.badge}>{totalUnreadMessages}</div>
                  ) : undefined}
                  {label === HEADER_LABELS.employerChatPage && !!totalUnreadMessages && totalUnreadMessages > 0 ? (
                    <div className={styles.badge}>{totalUnreadMessages}</div>
                  ) : undefined}
                </span>
              </Link>
            </li>
          ))}
          {!!tokenType && isPublic ? (
            <li>
              <Link
                className={cx(styles.navigationLink)}
                href={tokenType === TokenType.Candidate ? ROUTES.gesprekken : ROUTES.werkgeverZoekopdrachten}>
                <FontAwesomeIcon strokeWidth="20px" icon={faUser} />
                <span>Mijn account</span>
              </Link>
            </li>
          ) : undefined}
        </ul>
      </nav>
      <MenuButton open={menuOpen} onClick={toggleMenu} />
    </div>
  );
};

function isMenuItemActive(href: string, routerPathname: string) {
  if (href === ROUTES.werknemerHome && routerPathname === ROUTES.werknemerHome) {
    return true;
  }

  if (href === ROUTES.werkgeverHome && routerPathname === ROUTES.werkgeverHome) {
    return true;
  }

  if (routerPathname.includes(href)) {
    return true;
  }

  return false;
}

const MenuButton = ({ open, onClick }: { open: boolean; onClick?: MouseEventHandler<HTMLButtonElement> }) => (
  <button
    type="button"
    aria-label="Menu button"
    onClick={onClick}
    className={cx(styles.menuButton, { [styles.open]: open })}>
    <FontAwesomeIcon icon={open ? faXmark : faBars} />
  </button>
);
