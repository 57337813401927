/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Connection_candidate_view = {
    readonly uuid: string;
    status: Connection_candidate_view.status;
    shareStatus: Connection_candidate_view.shareStatus;
};
export namespace Connection_candidate_view {
    export enum status {
        CONCEPT = 'concept',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELED = 'canceled',
    }
    export enum shareStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
    }
}

